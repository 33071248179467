<div class="ns-center-content-container ns-text">
  <div class="ns-content">
    <img alt="{{ 'img_alt.hoopit_logo' | translate }}" src="/assets/img/logo/HoopitLogo_red.svg" />

    @if (requestSent) {
      <p>{{ 'password.reset_request_sent' | translate }}</p>
      <p>{{ 'password.reset_request_to_spam' | translate }}</p>

      <button (click)="goBack()" class="ns-button ns-transparent">{{ 'action.back_to_login' | translate }}</button>
    } @else {
      <p>{{ 'password.reset_request_info' | translate }}</p>

      <form [formGroup]="requestForm" class="ns-form ns-light">
        <app-phone-number
          [phoneCode]="requestForm.value.phoneCountryCode!"
          [phoneNumber]="requestForm.value.phoneMobile!"
          (phoneNumberInput)="handlePhoneNumberUpdate($event)"
        ></app-phone-number>
      </form>
      <button (click)="sendRequest()" [disabled]="requestForm.invalid || loading" class="ns-button ns-red">
        {{ 'password.reset' | translate }}
      </button>
      <button (click)="goBack()" class="ns-button ns-transparent">
        {{ 'password.remembered' | translate }}? {{ 'action.back_to_login' | translate }}
      </button>
    }
  </div>
</div>
