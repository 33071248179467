import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { finalize } from 'rxjs'

import { AuthService } from '../../../core/services'
import { PhoneNumberInput } from '../phone-number/interfaces/phone-number-input'
import { PhoneNumberComponent } from '../phone-number/phone-number.component'

@Component({
  selector: 'app-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss'],
  imports: [ReactiveFormsModule, TranslateModule, PhoneNumberComponent]
})
export class RequestPasswordResetComponent implements OnInit {
  @Output() back: EventEmitter<void> = new EventEmitter<void>()

  loading: boolean = false
  requestForm: FormGroup<{
    phoneCountryCode: FormControl<string>
    phoneMobile: FormControl<string>
  }>
  requestSent: boolean = false

  constructor(
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.requestForm = this.formBuilder.group({
      phoneCountryCode: [localStorage.getItem('lastPhoneCode') || '', [Validators.required]],
      phoneMobile: ['', [Validators.required]]
    })
  }

  handlePhoneNumberUpdate({ phoneCode, phoneNumber, isValid }: PhoneNumberInput): void {
    this.requestForm.controls.phoneCountryCode.patchValue(phoneCode)
    this.requestForm.controls.phoneMobile.patchValue(phoneNumber)
    this.requestForm.controls.phoneMobile.setErrors(isValid ? null : { invalidPhoneNumber: true })
  }

  sendRequest(): void {
    if (this.requestForm.invalid || this.loading) return

    const payload = this.requestForm.value.phoneCountryCode! + this.requestForm.value.phoneMobile!

    this.loading = true
    this.authService
      .sendResetRequest(payload)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(() => {
        this.requestSent = true
      })
  }

  goBack(): void {
    this.back.emit()
  }
}
